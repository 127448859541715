import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Footer from "../components/footer"
import NavBar from "../components/navbar"
import HeadData from "../data/HeadData"

const NotFoundPage = props => {
  return (
    <div>
      <HeadData title="Page Not Found" />
      <div className="h-full min-h-screen flex flex-col">
        <NavBar key="navbar" path={props.path} />
        <div className="flex flex-row flex-wrap bg-white justify-center flex-grow">
          <div className="w-full max-w-4xl px-10 mt-5 mb-10 min-h-400px flex flex-col items-center justify-center">
            <p className="font-sans mt-2 text-6xl text-center">
              Page Not Found
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default NotFoundPage
